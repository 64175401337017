.container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 24px;
    box-sizing: border-box;
    max-height: calc(100vh - 64px);
}

.container img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 0px) {
    .container{
       width:100vw;
       max-width: 100%;
    }
}

@media screen and (min-width: 760px) {
    .container{
        margin-left: 20vw;
        width:60vw
     }
}

@media screen and (min-width: 960px) {}

@media screen and (min-width: 1320px) {
 
}