/* Global styles to prevent unwanted scrollbars */
body, html {
  margin: 0;
  padding: 0;
  overflow: hidden; /* Keep this to prevent double scrollbars on the main window */
  height: 100%;
  width: 100%;
}

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden; /* Keep this to prevent double scrollbars on the main window */
}

/* Ensure all content containers handle their own scrolling */
.scrollable-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

/* Fix for Leaflet map container */
.leaflet-container {
  width: 100%;
  height: 100%;
}
