@media screen and (min-width: 0px) {
    .wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: linear-gradient(135deg, #731F3E, #8B2649);
        position: relative;
        overflow: hidden;
    }
    
    .wrapper::before {
        content: '';
        position: absolute;
        width: 200%;
        height: 200%;
        background: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23ffffff' fill-opacity='0.08' fill-rule='evenodd'/%3E%3C/svg%3E");
        animation: gradientBG 15s ease infinite;
        opacity: 0.8;
        pointer-events: none;
    }

    .header {
        padding: 16px 24px;
        background: rgba(0, 0, 0, 0.2);
        color: white;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .headerTitle {
        display: flex;
        align-items: center;
        gap: 12px;
        font-size: 1.2rem;
        color: white;
        text-decoration: none;
    }

    .headerLogo {
        height: 32px;
        width: auto;
    }

    .header::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 25%, transparent 50%);
        pointer-events: none;
    }

    .headerContent {
        max-width: 1400px;
        margin: 0 auto;
        width: 100%;
        position: relative;
    }

    .logoContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
        justify-content: center;
        align-items: center;
        padding: 16px;
        margin-bottom: 24px;
    }

    .logoItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 8px;
        color: white;
        background: rgba(255, 255, 255, 0.1);
        padding: 20px;
        border-radius: 16px;
        backdrop-filter: blur(8px);
        -webkit-backdrop-filter: blur(8px);
        transition: all 0.3s ease;
        border: 1px solid rgba(255, 255, 255, 0.2);
    }

    .logoItem:hover {
        transform: translateY(-5px);
        background: rgba(255, 255, 255, 0.15);
        border-color: rgba(255, 255, 255, 0.3);
    }

    .logoImage {
        height: 70px;
        object-fit: contain;
        filter: brightness(0) invert(1);
        transition: all 0.3s ease;
    }

    .logoItem:hover .logoImage {
        transform: scale(1.1);
        filter: brightness(0) invert(1) drop-shadow(0 0 8px rgba(255, 255, 255, 0.5));
    }

    .introText {
        text-align: center;
        max-width: 1000px;
        margin: 0 auto;
        padding: 24px;
    }

    .projectInfo {
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 24px;
        padding: 40px;
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;
        transition: all 0.5s ease;
    }

    .infoGrid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        gap: 24px;
        margin-top: 32px;
        opacity: 1;
        transform: translateY(0);
        transition: all 0.5s ease;
    }

    .infoCard {
        background: rgba(255, 255, 255, 0.95) !important;
        border-radius: 16px !important;
        box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1) !important;
        overflow: hidden !important;
        transition: all 0.3s ease !important;
        transform: translateY(0);
        height: 100%;
    }

    .infoCard:hover {
        transform: translateY(-10px);
        box-shadow: 0 12px 48px rgba(0, 0, 0, 0.2) !important;
    }

    .infoCardHeader {
        background: linear-gradient(135deg, #731F3E, #8B2649) !important;
        padding: 20px !important;
        display: flex !important;
        align-items: center !important;
        gap: 16px !important;
        position: relative;
        overflow: hidden;
    }

    .infoCardHeader::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: radial-gradient(circle at top right, rgba(255,255,255,0.2), transparent 70%);
    }

    .infoCardContent {
        padding: 24px !important;
    }

    .cardItem {
        padding: 12px 16px;
        color: #444;
        font-size: 0.95rem;
        display: flex;
        align-items: center;
        gap: 12px;
        border-radius: 8px;
        transition: all 0.2s ease;
        position: relative;
        z-index: 1;
    }

    .cardItem:hover {
        background: rgba(115, 31, 62, 0.1);
        transform: translateX(8px);
        color: #731F3E;
    }

    .cardItem::before {
        content: '•';
        color: #731F3E;
        font-size: 1.5em;
        line-height: 0;
    }

    .actionContainer {
        margin-top: 48px;
        text-align: center;
        padding: 24px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 16px;
        backdrop-filter: blur(8px);
        -webkit-backdrop-filter: blur(8px);
    }

    .loadMapButton {
        background: linear-gradient(135deg, #2E7D32, #1B5E20) !important;
        color: white !important;
        padding: 16px 48px !important;
        font-size: 1.1rem !important;
        border-radius: 12px !important;
        text-transform: uppercase !important;
        letter-spacing: 2px !important;
        transition: all 0.3s ease !important;
        border: none !important;
        box-shadow: 0 4px 15px rgba(46, 125, 50, 0.3) !important;
        position: relative;
        overflow: hidden;
    }

    .loadMapButton::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(255,255,255,0.1), transparent);
        transition: transform 0.3s ease;
        transform: translateY(-100%);
    }

    .loadMapButton:hover {
        transform: translateY(-3px);
        box-shadow: 0 6px 20px rgba(46, 125, 50, 0.4) !important;
    }

    .loadMapButton:hover::before {
        transform: translateY(0);
    }

    .mapView {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        z-index: 1000;
        opacity: 0;
        pointer-events: none;
        transition: all 0.5s ease;
    }

    .mapView.visible {
        opacity: 1;
        pointer-events: all;
    }

    .mapContainer {
        min-height: 100vh;
        width: 100%;
        position: relative;
    }

    .mapWrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .closeMapButton {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1001;
        background: white !important;
        box-shadow: 0 4px 12px rgba(0,0,0,0.1) !important;
    }

    .container {
        background: white;
        border-radius: 12px;
        padding: 20px;
        width: 300px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    }

    .alertCard {
        background: linear-gradient(135deg, #fff5f5, #fff) !important;
        border-left: 4px solid #d32f2f !important;
        border-radius: 12px !important;
    }
    
    .circularProgress {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
    }

    .aside {
        position: fixed;
        top: 50%;
        right: 24px;
        transform: translateY(-50%) translateX(100%);
        opacity: 0;
        transition: all 0.3s ease;
        z-index: 1000;
    }

    .aside.animated {
        transform: translateY(-50%) translateX(0);
        opacity: 1;
    }
}

@media screen and (min-width: 760px) {
    .headerContent {
        padding: 0 32px;
    }

    .logoContainer {
        justify-content: space-between;
        padding: 24px 0;
    }

    .introText {
        text-align: left;
        padding: 32px 0;
    }

    .container {
        width: 350px;
    }

    .projectInfo {
        padding: 40px;
    }
}

@media screen and (min-width: 960px) {
    .headerContent {
        padding: 0 48px;
    }

    .projectInfo {
        padding: 48px;
    }

    .logoItem {
        padding: 24px;
    }
}

@media screen and (min-width: 1320px) {
    .headerContent {
        padding: 0 64px;
    }

    .projectInfo {
        padding: 56px;
    }
}

@media screen and (max-width: 768px) {
    .mainContent {
        padding: 24px 16px;
    }

    .projectInfo {
        padding: 24px;
    }

    .infoGrid {
        grid-template-columns: 1fr;
    }

    .titleSection {
        padding: 80px 20px;
    }

    .titleMain {
        font-size: 2.5rem !important;
    }

    .subtitle {
        font-size: 1.1rem !important;
        padding: 0 20px;
    }
}

.mainContent {
    flex: 1;
    width: 100%;
    position: relative;
    z-index: 1;
    overflow: auto;
    max-height: calc(100vh - 64px);
}

.titleSection {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 40px 24px;
    position: relative;
    background: radial-gradient(circle at center, rgba(255,255,255,0.1) 0%, transparent 70%);
    box-sizing: border-box;
}

.titleSection::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: 100px;
    background: linear-gradient(to bottom, transparent, rgba(255,255,255,0.3), transparent);
}

.titleSection::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 1px;
    height: 100px;
    background: linear-gradient(to top, transparent, rgba(255,255,255,0.3), transparent);
}

.titleMain {
    font-size: clamp(2.5rem, 5vw, 4.5rem) !important;
    font-weight: 800 !important;
    line-height: 1.2 !important;
    margin-bottom: 32px !important;
    background: linear-gradient(to right, #fff, #f0f0f0);
    -webkit-background-clip: text !important;
    background-clip: text !important;
    color: transparent !important;
    text-shadow: 0 4px 12px rgba(0,0,0,0.1);
    position: relative;
    z-index: 1;
    letter-spacing: -1px !important;
    max-width: 1200px;
}

.titleMain::after {
    content: '';
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
    width: 160px;
    height: 4px;
    background: linear-gradient(to right, transparent, #fff, transparent);
    border-radius: 2px;
}

.subtitle {
    font-size: clamp(1.1rem, 2vw, 1.4rem) !important;
    line-height: 1.6 !important;
    color: rgba(255, 255, 255, 0.9) !important;
    max-width: 800px;
    margin: 0 auto !important;
    position: relative;
    z-index: 1;
    text-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.scrollIndicator {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 50px;
    border: 2px solid rgba(255,255,255,0.3);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    padding-top: 10px;
}

.scrollIndicator::before {
    content: '';
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 50%;
    animation: scrollAnim 2s infinite;
}

@keyframes scrollAnim {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(20px);
        opacity: 0;
    }
}

@keyframes gradientBG {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    50% {
        transform: translate(-25%, -25%) rotate(180deg);
    }
    100% {
        transform: translate(0, 0) rotate(360deg);
    }
}

.mapButtonContainer {
    margin-top: 48px;
    text-align: center;
    padding: 32px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.fullWidthMap {
    width: 100%;
    min-height: 80vh;
    position: relative;
    margin-top: 48px;
    border-radius: 24px;
    overflow: hidden;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.mapContainer {
    width: 100%;
    height: 80vh;
    position: relative;
    overflow: hidden;
    border-radius: 16px;
}

.mapWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.closeMapButton {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1001;
    background: white !important;
    box-shadow: 0 4px 12px rgba(0,0,0,0.1) !important;
}

.closeMapButton:hover {
    background: #f5f5f5 !important;
    transform: scale(1.05);
}

.mapSection {
    width: 100%;
    padding: 48px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    margin-top: 64px;
    border-radius: 32px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
    position: relative;
}

.mapTitle {
    color: white !important;
    text-align: center;
    margin-bottom: 16px !important;
    font-weight: 800 !important;
    font-size: 3rem !important;
    text-shadow: 0 2px 4px rgba(0,0,0,0.2);
    background: linear-gradient(to right, #fff, #f0f0f0);
    -webkit-background-clip: text !important;
    background-clip: text !important;
    color: transparent !important;
    letter-spacing: -1px;
    position: relative;
}

.mapTitle::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 4px;
    background: linear-gradient(to right, transparent, #fff, transparent);
    border-radius: 2px;
}

.mapSubtitle {
    color: rgba(255, 255, 255, 0.9) !important;
    text-align: center;
    margin-bottom: 32px !important;
    max-width: 800px;
    font-size: 1.2rem !important;
    line-height: 1.6 !important;
    text-shadow: 0 2px 4px rgba(0,0,0,0.1);
    font-weight: 400 !important;
}

.fullWidthMap {
    width: calc(100% - 48px);
    min-height: 80vh;
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
    border: 2px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.mapContainer {
    width: 100%;
    height: 80vh;
    position: relative;
}

.mapWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 16px;
    overflow: hidden;
}

@media screen and (max-width: 768px) {
    .mapSection {
        padding: 32px 16px;
        margin-top: 48px;
    }

    .fullWidthMap {
        width: calc(100% - 24px);
    }

    .mapTitle {
        font-size: 2rem !important;
        padding: 0 16px;
    }

    .mapSubtitle {
        font-size: 1.1rem !important;
        padding: 0 24px;
    }
}

.sectionTitle {
    color: white !important;
    text-align: center;
    margin-bottom: 16px !important;
    font-weight: 700 !important;
    background: linear-gradient(to right, #fff, #f0f0f0);
    -webkit-background-clip: text !important;
    background-clip: text !important;
    color: transparent !important;
    text-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: relative;
}

.sectionTitle::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 3px;
    background: linear-gradient(to right, transparent, #fff, transparent);
    border-radius: 2px;
}

.sectionSubtitle {
    color: rgba(255, 255, 255, 0.9) !important;
    text-align: center;
    margin-bottom: 40px !important;
    max-width: 800px;
    margin: 0 auto !important;
    font-size: 1.1rem !important;
    line-height: 1.6 !important;
    text-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.infoCardHeader {
    background: linear-gradient(135deg, #731F3E, #8B2649) !important;
    padding: 24px !important;
    display: flex !important;
    align-items: flex-start !important;
    gap: 16px !important;
    position: relative;
    overflow: hidden;
}

.infoCardHeader > div {
    flex: 1;
}

.logoSpace {
    width: 120px;
    height: 120px;
    background: white;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition: all 0.3s ease;
}

.institutionLogo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: all 0.3s ease;
}

.logoSpace:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.logoSpace:hover .institutionLogo {
    transform: scale(1.05);
}

@media screen and (max-width: 768px) {
    .logoSpace {
        width: 100px;
        height: 100px;
        padding: 12px;
    }
}

/* Emergency Contact Section Styles */
.emergencySection {
    padding: 80px 24px;
    background: linear-gradient(135deg, rgba(115, 31, 62, 0.9), rgba(139, 38, 73, 0.9));
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.emergencySection::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M54.627 0l.83.828-1.415 1.415L51.8 0h2.827zM5.373 0l-.83.828L5.96 2.243 8.2 0H5.374zM48.97 0l3.657 3.657-1.414 1.414L46.143 0h2.828zM11.03 0L7.372 3.657 8.787 5.07 13.857 0H11.03zm32.284 0L49.8 6.485 48.384 7.9l-7.9-7.9h2.83zM16.686 0L10.2 6.485 11.616 7.9l7.9-7.9h-2.83zm20.97 0l9.315 9.314-1.414 1.414L34.828 0h2.83zM22.344 0L13.03 9.314l1.414 1.414L25.172 0h-2.83zM32 0l12.142 12.142-1.414 1.414L30 .828 17.272 13.556l-1.414-1.414L28 0h4zM.284 0l28 28-1.414 1.414L0 2.544v2.83L25.456 30l-1.414 1.414L0 7.97v2.828L22.627 30l-1.414 1.414L0 13.4v2.83L19.8 30l-1.414 1.414L0 18.83v2.828L16.97 30l-1.414 1.414L0 24.258v2.83L14.142 30l-1.414 1.414L0 29.686V60h60V0H.284zM60 60L30 30l30-30v60z' fill='%23ffffff' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
    pointer-events: none;
}

.emergencyTitle {
    font-size: clamp(2rem, 4vw, 3rem) !important;
    font-weight: 700 !important;
    text-align: center;
    margin-bottom: 16px !important;
    color: white !important;
    position: relative;
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
}

.emergencyTitle::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    background: linear-gradient(to right, #FF4081, #F50057);
    border-radius: 2px;
}

.emergencySubtitle {
    text-align: center;
    color: rgba(255, 255, 255, 0.9) !important;
    margin-bottom: 48px !important;
    max-width: 800px;
    margin-left: auto !important;
    margin-right: auto !important;
    font-size: 1.1rem !important;
    line-height: 1.6 !important;
}

.emergencyGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 24px;
    margin-bottom: 40px;
}

.emergencyCard {
    background: rgba(255, 255, 255, 0.1) !important;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 12px !important;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.2);
    height: 100%;
}

.emergencyCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2) !important;
}

.emergencyCardHeader {
    background: rgba(0, 0, 0, 0.2);
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.emergencyIconContainer {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.emergencyCardTitle {
    color: white !important;
    font-weight: 600 !important;
    flex: 1;
}

.emergencyCardContent {
    padding: 20px !important;
}

.emergencyDescription {
    color: rgba(255, 255, 255, 0.9) !important;
    margin-bottom: 12px !important;
    line-height: 1.6 !important;
}

.emergencyAvailability {
    color: rgba(255, 255, 255, 0.7) !important;
    font-style: italic;
    margin-bottom: 16px !important;
}

.emergencyPhone {
    color: #FF4081 !important;
    font-weight: 700 !important;
    font-size: 1.8rem !important;
    text-align: center;
    margin-top: 16px !important;
    letter-spacing: 1px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.emergencyMessage {
    background: linear-gradient(to right, rgba(255, 64, 129, 0.2), rgba(245, 0, 87, 0.2)) !important;
    padding: 24px !important;
    border-radius: 12px !important;
    max-width: 800px;
    margin: 0 auto;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1) !important;
}

.emergencyMessage p {
    color: white !important;
    font-size: 1.2rem !important;
    font-weight: 500 !important;
}

@media screen and (max-width: 768px) {
    .emergencyGrid {
        grid-template-columns: 1fr;
    }
    
    .emergencyTitle {
        font-size: 1.8rem !important;
    }
    
    .emergencySubtitle {
        font-size: 1rem !important;
    }
    
    .emergencyMessage p {
        font-size: 1.1rem !important;
    }
}

/* Estilos para el buscador de direcciones */
.searchContainer {
    max-width: 600px;
    margin: 0 auto 24px;
    border-radius: 12px !important;
    overflow: hidden;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15) !important;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.9) !important;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.searchContainer:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2) !important;
}

.searchInput {
    padding: 8px 12px !important;
}

.searchInput input {
    padding: 12px !important;
    font-size: 1rem !important;
}

/* Estilos para el marcador de búsqueda */
.searchMarker {
    background: transparent;
}

.searchMarker > div {
    width: 30px;
    height: 30px;
    background: rgba(255, 64, 129, 0.4);
    border-radius: 50%;
    position: relative;
    animation: pulse 1.5s infinite;
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchMarker > div::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    background: #FF4081;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(255, 64, 129, 0.8);
}

@keyframes pulse {
    0% {
        transform: scale(0.8);
        opacity: 1;
    }
    70% {
        transform: scale(1.5);
        opacity: 0;
    }
    100% {
        transform: scale(0.8);
        opacity: 0;
    }
}