.container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 24px;
    box-sizing: border-box;
    max-height: calc(100vh - 64px); /* Add max-height to enable scrolling */
}

@media screen and (min-width: 0px) {
    .container {
        width: 100%;
        max-width: 100%;
    }
}

@media screen and (min-width: 760px) {
    .container {
        width: 100%;
        max-width: 100%;
    }
} 